<script>
import TablePage from '../../../../components/table_page';
import request from '../../../../utils/request';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('version_manage_table');
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'appVersion') {
        this.formConfig = {
          type: 'view',
          id: row.id,
          disabled: true,
        };
        this.modalConfig.title = '查看';
        this.openDrawer();
      }
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.formConfig = {};
      if (val.code === 'add') {
        this.formConfig = {
          type: val.code,
          disabled: false,
        };
        this.modalConfig.title = '新增';
        this.openDrawer();
      } else if (val.code === 'edit') {
        this.formConfig = {
          type: val.code,
          id: row.id,
          disabled: false,
        };
        this.modalConfig.title = '编辑';
        this.openDrawer();
      } else if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          id: row.id,
          disabled: true,
        };
        this.modalConfig.title = '查看';
        this.openDrawer();
      }
    },
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
</script>
