<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('version_manage_form');
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'file') {
        v.props = {
          ...v.props,
          limit: 1,
          showFileList: true,
          uploadType: 'file',
          listType: 'text',
          fileMsg: ' ',
          // fileMsg: '只能上传pdf/word/excel/ppt/png/jpg文件',
          // accept: '.pdf,.doc,.xls,.ppt,.png,.jpg,.jpeg,.xlsx,.docx,.pptx',
        };
        v.on = {
          ...v.on,
          onGetFileList: (val) => {
            if (val && Array.isArray(val) && val.length > 0) {
              this.setValue({
                downloadUrl: val[0].url,
              });
            }
          },
        };
      } else if (v.field === 'versionCode') {
        v.validate = [
          ...v.validate,
          {
            trigger: 'blur',
            pattern: '^[0-9]*$',
            message: '仅能输入数字',
          },
        ];
      }
      if (this.formConfig.type === 'view') {
        v.props = {
          ...v.props,
          disabled: true,
        };
        this.hiddenFields(true, 'file');
      }
    });
    this.rule = rule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      request.get('/upload/app/version/detail', { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.setValue(result);
        }
      });
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      if (!formData) return false;
      if (formData) {
        const url = '/upload/app/version/saveOrUpdateEntity';
        const params = { ...formData };
        if (this.formConfig.type === 'edit') {
          params.id = this.formConfig.id;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
